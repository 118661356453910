<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 13L20 18C20 19.1046 19.1046 20 18 20L6 20C4.89543 20 4 19.1046 4 18L4 13"
      :stroke="getBaseColor(darkMode)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.2071 5.79289C15.5976 6.18342 15.5976 6.81658 15.2071 7.20711C14.8166 7.59763 14.1834 7.59763 13.7929 7.20711L13 6.41421L13 15.5C13 16.0523 12.5523 16.5 12 16.5C11.4477 16.5 11 16.0523 11 15.5L11 6.41421L10.2071 7.20711C9.81658 7.59763 9.18342 7.59763 8.79289 7.20711C8.40237 6.81658 8.40237 6.18342 8.79289 5.79289L11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289L15.2071 5.79289Z"
      :fill="accent"
    />
  </svg>
</template>
